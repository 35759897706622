import { useMemo } from 'react';

// Styles
import { StyledLink, StyledLinkProps } from './styles';

// Intl
import { defaultLocale } from 'i18n';
import { useLocale } from 'next-intl';

// Types
export interface LinkProps extends StyledLinkProps {}

// Link
export const Link = ({
  href = '',
  locale,
  ...props
}: LinkProps) => {
  const currentLocale = useLocale();
  const newLocale = useMemo(() => {
    if (locale) {
      return locale;
    }
    return currentLocale || defaultLocale;
  }, [locale, currentLocale]);
  const hrefWithLocale = useMemo(() => {
    const hrefHasCurrentLocale = (href as string)?.startsWith?.(`/${currentLocale}`);
    if (hrefHasCurrentLocale) {
      return (href as string)?.replace?.(`/${currentLocale}`, `/${newLocale}`);
    }
    const hrefHasNewLocale = (href as string)?.startsWith?.(`/${newLocale}`);
    if (!hrefHasNewLocale) {
      return `/${newLocale}${(href as string)?.startsWith?.('/') ? '' : '/'}${href}`;
    }
    return href;
  }, [href, newLocale]);
  return <StyledLink href={hrefWithLocale} {...props}>
      {props.children}
    </StyledLink>;
};