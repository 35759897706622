import { useMemo } from 'react';

// Styles
import { StyledDropdown, Link, LangLink, DropdownProps } from './styles';

// Icons
import LogoutSvg from 'public/icons/logout.svg';
import HomeSvg from 'public/icons/home.svg';
import TranslationSvg from 'public/icons/translation.svg';
import GridSvg from 'public/icons/grid.svg';

// Hooks
import { useGetUserData } from 'modules/auth/api/hooks/useGetUserData';
import { useLogout } from 'modules/auth/api/hooks/useLogout';
import { useTranslations, useLocale } from 'next-intl';
import { usePathname } from 'next/navigation';
import { useAppRouterCreatePath } from '@tools/next-use-app-router-create-path';

// Components
import { AccordionMenuItem, Group, IconMenuItem, Separator } from '../Dropdown';

// Constants
import { locales } from 'i18n';

// Types
export interface ProfileDropdownProps extends DropdownProps {}
export const ProfileDropdown = ({
  ...props
}: ProfileDropdownProps) => {
  const {
    data: {
      isAuth
    } = {}
  } = useGetUserData();
  const t = useTranslations('UI.PROFILE_DROPDOWN');
  const currentLocale = useLocale();
  const pathname = usePathname();
  const {
    createPathname
  } = useAppRouterCreatePath();
  const {
    mutate: logout
  } = useLogout();
  const languagesSubContent = useMemo(() => {
    return <AccordionMenuItem LeftIcon={TranslationSvg} label={t('LANGUAGE')}>
        {locales.map(locale => {
        return <LangLink key={locale} href={createPathname({})} locale={locale} $active={currentLocale === locale}>
              {t(`LANGUAGES.${locale?.toUpperCase?.()}` as any)}
            </LangLink>;
      })}
      </AccordionMenuItem>;
  }, [currentLocale, t, createPathname]);
  const menuContent = useMemo(() => <>
        {pathname !== `/${currentLocale}` && isAuth && <Group>
            <Link href={'/'}>
              <IconMenuItem Icon={HomeSvg} content={t('HOME')} />
            </Link>
          </Group>}

        {!pathname?.includes?.('/dashboard') && <Group>
            <Link href={'/dashboard/users'}>
              <IconMenuItem Icon={GridSvg} content={t('DASHBOARD')} />
            </Link>
          </Group>}

        {languagesSubContent}

        {isAuth && <>
            <Separator />
            <Group>
              <IconMenuItem onClick={() => logout({})} Icon={LogoutSvg} content={t('LOGOUT')} />
            </Group>
          </>}
      </>, [languagesSubContent, logout, t, isAuth, currentLocale]);
  return <StyledDropdown content={menuContent} {...props}>
      {props.children}
    </StyledDropdown>;
};