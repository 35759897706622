// Utils
import rest, { queryClient } from 'shared/libs/rest';

// Constants
export const QUERY_KEY_LOGOUT = '/auth/logout';

// Types
export interface LogoutRequest {
  href?: string;
}

export interface LogoutResponse {
  success: boolean;
}

// Api function
export const apiLogout = async ({
  href = '/auth/sign-in',
}: LogoutRequest = {}): Promise<LogoutResponse> => {
  try {
    const response = await rest.get(QUERY_KEY_LOGOUT, true, {});
  } catch (error) {}

  rest.removeTokens();

  if (global?.window) {
    global.window.location.href = href;
  }

  queryClient.clear();

  return { success: true };
};
