import React from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';

// Types
export interface LinkProps extends NextLinkProps {
  LinkComponent?: React.FC<LinkProps> | React.FC<any>;
  children?: React.ReactNode;
  disabled?: boolean;
  target?: '_blank' | '_self' | '_parent' | '_top';
}
export const Link = ({
  LinkComponent = NextLink,
  disabled = false,
  ...props
}: LinkProps) => {
  return <LinkComponent data-disabled={disabled} {...props}>
      {props.children}
    </LinkComponent>;
};
export default Link;