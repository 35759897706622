import { styled, css } from 'styled-components';

// Components
import { Link as ParentLink, LinkProps as ParentLinkProps } from '@ui/next-styled-link';

// Types
export interface StyledLinkProps extends ParentLinkProps {}

// Styled css
export const linkCss = css<StyledLinkProps>``;

// Styled Components
export const StyledLink = styled(ParentLink)<StyledLinkProps>`
  ${linkCss};
`;