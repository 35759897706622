// Utils
import rest from 'shared/libs/rest';

// Constants
export const QUERY_KEY_GET_MANAGER_DATA = '/manager-auth/get-info';

// Types
export interface GetUserDataRequest {}

export interface GetManagerDataResponse {
  id: number;
  isAuth: boolean;
  name: string;
  surname: string;
  passportId: string;
  nationality: string;
  qrCodeId: number;
  address: string;
  residenceStatus: string;
  phoneNumber: string;
  familySize: number;
  isBlocked?: boolean;
}

// Api function
export const apiGetUserData = async (
  data: GetUserDataRequest = {}
): Promise<GetManagerDataResponse> => {
  const response = await rest.get(
    QUERY_KEY_GET_MANAGER_DATA,
    true,
    {},
    { options: { cache: 'no-store' } }
  );

  if (response) {
    response.isAuth = !!response?.id;
  }

  return response;
};
