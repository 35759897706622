// Hooks
import {
  useMutation,
  UseMutationOptions,
  RequestError,
} from 'shared/libs/rest';
import { useLocale } from 'next-intl';

// Api
import {
  apiLogout,
  LogoutRequest,
  LogoutResponse,
  QUERY_KEY_LOGOUT,
} from '../apiLogout';

// Request hook
export const useLogout = (
  defParams?: LogoutRequest,
  options?: UseMutationOptions<LogoutResponse, RequestError, LogoutRequest>
) => {
  const locale = useLocale();

  return useMutation<LogoutResponse, RequestError, LogoutRequest>({
    mutationKey: QUERY_KEY_LOGOUT,
    mutationFn: (params?: LogoutRequest) =>
      apiLogout({
        href: `/${locale}`,
        ...(defParams || {}),
        ...(params || {}),
      }),
    ...(options || ({} as any)),
  });
};
