import { styled, css } from 'styled-components';

// Components
import { Dropdown, DropdownProps } from '../Dropdown';
import { Link as UILink, LinkProps as UILinkProps } from '../Link';

// Re export types
export type { DropdownProps };

// Types
export interface LinkProps extends UILinkProps {
  $active?: boolean;
}
export const StyledDropdown = styled(Dropdown)<DropdownProps>``;
export const Link = styled(UILink)<LinkProps>`
  text-decoration: none;

  ${({
  $active
}) => css`
    color: inherit;
    ${$active && 'text-decoration: underline;'}
  `};
`;
export const LangLink = styled(Link)<LinkProps>`
  display: block;
  padding: 6px 8px;

  ${({
  theme
}) => css`
    border-radius: ${theme.borderRadius.xs};
    &:hover {
      background-color: ${theme.color.grey50};
    }
  `};
`;